import { usePostStore } from './store';
import { useFrame, useThree } from '@react-three/fiber';
import { useEffect, useMemo } from 'react';
import {
  BloomEffect,
  EffectComposer,
  EffectPass,
  RenderPass,
  SMAAEffect,
  SMAAPreset,
  VignetteEffect,
} from 'postprocessing';
import { HalfFloatType, ShaderChunk } from 'three';
import { measure } from '../../index';

// fix flickering of bloom pass on M1 macs
ShaderChunk.normalmap_pars_fragment = ShaderChunk.normalmap_pars_fragment.replace('mapN.z', `max(mapN.z, 0.5001)`);

export default function Renderer({ forceRendering = false }) {
  const { gl, scene, camera, size } = useThree();
  const isPaused = usePostStore(state => state.isPaused);
  const { renderConfiguration } = usePostStore();

  const composer = useMemo(() => {
    return new EffectComposer(gl, {
      multisampling: renderConfiguration.postMultisampling,
      frameBufferType: HalfFloatType,
    });
  }, []);

  const effects = useMemo(() => {
    composer.multisampling = renderConfiguration.postMultisampling;
    composer.reset();

    const renderPass = new RenderPass(scene, camera);
    composer.addPass(renderPass);

    let effects = {};
    const effectList = [];
    const add = o => {
      effects = { ...effects, ...o };
      const effect = o[Object.keys(o)[0]];
      effectList.push(effect);
    };

    if (renderConfiguration.bloom.enabled) {
      const bloom = new BloomEffect({ mipmapBlur: true, ...renderConfiguration.bloom });
      bloom.mipmapBlurPass.radius = 1;
      add({ bloom });
    }

    if (renderConfiguration.smaa.enabled) {
      add({ smaa: new SMAAEffect({ preset: SMAAPreset.LOW }) });
    }

    if (renderConfiguration.vignette.enabled) {
      add({
        vignette: new VignetteEffect({
          ...renderConfiguration.vignette,
        }),
      });
    }

    const effect = new EffectPass(camera, ...effectList);
    composer.addPass(effect);

    return effects;
  }, [
    renderConfiguration.postEnabled,
    renderConfiguration.postMultisampling,
    renderConfiguration.smaa.enabled,
    renderConfiguration.bloom.enabled,
    renderConfiguration.vignette.enabled,
  ]);

  useEffect(() => {
    const config = renderConfiguration;

    if (effects.bloom) {
      effects.bloom.intensity = config.bloom.intensity;
      effects.bloom.luminanceMaterial.threshold = config.bloom.luminanceThreshold;
      effects.bloom.luminanceMaterial.smoothing = config.bloom.luminanceSmoothing;
      effects.bloom.mipmapBlurPass.radius = config.bloom.radius;
    }

    if (effects.vignette) {
      effects.vignette.uniforms.get('offset').value = config.vignette.offset;
      effects.vignette.uniforms.get('darkness').value = config.vignette.darkness;
    }
  }, [renderConfiguration]);

  useEffect(() => {
    composer.setSize(size.width, size.height);
  }, [size, renderConfiguration.pixelRatio]);

  useFrame((context, delta) => {
    if (isPaused && !forceRendering) {
      return;
    }

    measure.count = 0;
    measure.skip = 0;
    gl.info.autoReset = false;
    gl.info.reset();
    gl.clear();

    if (renderConfiguration.postEnabled) {
      composer.render(delta);
    } else {
      gl.render(scene, camera);
    }

    console.log(measure);
  }, 100);
  return null;
}
