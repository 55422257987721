import create from 'utilities/zustand/create';
import { getInitialCustomizationConfiguration } from './config';
import { useUserStore } from '../UserService';

export const useAvatarStore = create((set, get) => ({
  customizationConfiguration: getInitialCustomizationConfiguration(),
  setInitialConfiguration: (initialAppearance = {}) => {
    set({ customizationConfiguration: getInitialCustomizationConfiguration() });
    const { customizationConfiguration } = get();
    for (const categoryId in initialAppearance) {
      const categoryIndex = get().getCategoryIndexById(categoryId);

      if (categoryIndex === -1) {
        continue;
      }
      const category = customizationConfiguration[categoryIndex];

      for (const type in initialAppearance[categoryId]) {
        const panelIndex = category.optionPanels.findIndex(op => op.type === type);
        const selections = category.optionPanels[panelIndex].selections;
        for (const selectionName in initialAppearance[categoryId][type]) {
          const selectionIndex = selections.findIndex(s => s.name === selectionName);
          selections[selectionIndex] = {
            ...selections[selectionIndex],
            index: initialAppearance[categoryId][type][selectionName],
          };
        }
      }
    }
    set({ customizationConfiguration });
  },
  getSelectedCustomizationCategoryCamera: () => {
    const selectedCategory = get().customizationConfiguration.find(cc => cc.selected);
    if (selectedCategory == null) return null;
    const camera = selectedCategory.camera;
    return camera == null ? null : camera;
  },
  selectCustomizationCategory: (id, selected) => {
    const newCustomizationOptions = get().customizationConfiguration.map(cc => {
      if (id === cc.id) return { ...cc, selected: selected };
      else if (cc.selected) return { ...cc, selected: false };
      else return cc;
    });
    set({ customizationConfiguration: newCustomizationOptions });
  },
  deselectCustomizationCategories: () => {
    const newCustomizationOptions = get().customizationConfiguration.map(cc => {
      return { ...cc, selected: false };
    });
    set({ customizationConfiguration: newCustomizationOptions });
  },
  getCategoryIndexById: categoryId => {
    return get().customizationConfiguration.findIndex(cc => cc.id === categoryId);
  },
  selectCustomizationOption: (categoryId, panelIndex, optionIndex, selectableIndex = 0) => {
    const { customizationConfiguration } = get();
    const categoryIndex = get().getCategoryIndexById(categoryId);
    if (categoryIndex === -1) return;
    customizationConfiguration[categoryIndex].customized = true;
    const { selections } = customizationConfiguration[categoryIndex].optionPanels[panelIndex];
    selections[selectableIndex] = {
      ...selections[selectableIndex],
      index: optionIndex,
    };
    set({ customizationConfiguration: [...customizationConfiguration] });
    const newAppearance = get().getAppearance();
    useUserStore.getState().previewAppearance(newAppearance);
  },
  getAppearance: () => {
    const appearance = {};
    get().customizationConfiguration.forEach(category => {
      appearance[category.id] = {};
      category.optionPanels.forEach(panel => {
        appearance[category.id][panel.type] = {};
        panel.selections.forEach(selection => {
          appearance[category.id][panel.type][selection.name] = selection.index;
        });
      });
    });
    return appearance;
  },
  computeAppearanceValues: appearance => {
    const appearanceValues = {};
    get().customizationConfiguration.forEach(category => {
      appearanceValues[category.id] = {};
      category.optionPanels.forEach(panel => {
        appearanceValues[category.id][panel.type] = {};
        panel.selections.forEach(selection => {
          let index = null;
          try {
            index = appearance[category.id][panel.type][selection.name];
          } catch (e) {
            index = 0;
          }
          appearanceValues[category.id][panel.type][selection.name] = panel.options[index];
        });
      });
    });
    return appearanceValues;
  },
}));
