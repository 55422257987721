import create from 'utilities/zustand/create';
import axios from 'axios';
import debugStore from 'storage/debug';

export const LOCAL = 'LOCAL';
export const DEVELOPMENT = 'DEVELOPMENT';
export const PRODUCTION = 'PRODUCTION';

const INTEGRATIONS = [
  { hostname: 'develop.virtual-experience.demodern.com', target: DEVELOPMENT },
  { hostname: 'virtual-experience.demodern.com', target: PRODUCTION },
  { hostname: 'develop.corporate-metaverse.com', target: DEVELOPMENT },
  { hostname: 'corporate-metaverse.com', target: PRODUCTION },
  { hostname: 'virtual-experience.de', target: PRODUCTION },
  { hostname: 'localhost', target: DEVELOPMENT },
];

const BACKEND_ENDPOINTS = {
  [LOCAL]: `http://${location.hostname}:1337/`,
  [DEVELOPMENT]: 'https://develop.strapi.virtual-experience.demodern.com/',
  [PRODUCTION]: 'https://strapi.virtual-experience.demodern.com/',
};

export const useBuildStore = create((set, get) => ({
  integration: '',
  init: () => {
    const hostname = window.location.hostname;

    // search for the first rule that matches
    let defaultIntegration = DEVELOPMENT;
    for (const { hostname: matchHostname, target } of INTEGRATIONS) {
      if (hostname.endsWith(matchHostname)) {
        defaultIntegration = target;
        break;
      }
    }
    // eslint-disable-next-line no-console
    const debugSocketTarget = debugStore.getSocketTarget();
    const integration = debugSocketTarget === 'AUTO' ? defaultIntegration : debugSocketTarget;

    // eslint-disable-next-line no-console
    console.log('Integration default:', defaultIntegration, 'used:', integration);
    set({ integration });
  },
  getBackendUrl: () => {
    const { integration } = useBuildStore.getState();
    return BACKEND_ENDPOINTS[integration];
  },

  getBackendStatus: async () => {
    const backendUrl = get().getBackendUrl();
    let data;
    try {
      const response = await axios.get(backendUrl + 'status');
      data = response.data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    if (data) {
      const { status, hubId } = data;
      return { status, hubId };
    }
    return {};
  },
}));
