import React, { useEffect, useState } from 'react';
import Character from '../../Play/Character';
import { useFrameAfterMount } from '../../../utilities/hooks';

function makeCharacter(radians) {
  return {
    radians,
    configuration: {},
    playerData: {
      reaction: 10,
      position: [-6 + Math.sin(radians) * 10, -1, -20 + Math.cos(radians) * 10],
      rotation: 0,
      velocity: [0, 0, 1, 0],
    },
  };
}

export default function AvatarPerformance() {
  const [characters, setCharacters] = useState([]);

  useEffect(() => {
    const amount = 75;
    const result = [];
    for (let i = 0; i < amount; i++) {
      result.push(makeCharacter((i / amount) * Math.PI * 2));
    }
    setCharacters(result);
  }, []);

  useFrameAfterMount(context => {
    const t = context.clock.elapsedTime;
    characters.forEach(character => {
      const radians = character.radians + t * 0.5;
      const { playerData } = character;
      playerData.position[0] = -6 + Math.sin(radians) * 6;
      playerData.position[2] = -20 + Math.cos(radians) * 6;
      playerData.rotation = 1.5 + radians;
      playerData.velocity[0] = Math.sin(radians);
      playerData.velocity[2] = Math.cos(radians);
    });
  });

  return characters.map((character, i) => {
    return (
      <Character
        key={i}
        name={'Character-Preload'}
        visible={true}
        frustumCulled={true}
        playerData={character.playerData}
        reactionId={0}
        isSelf={false}
        indicatorVisible={false}
        appearance={character.configuration}
      />
    );
  });
}
